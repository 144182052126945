import React from "react";

const ComingSoon = () => {
  return (
    <div className="coming-soon">
      <div className="content">
        <h1>clif.ai</h1>
        <h2>Exciting Things Are Coming Your Way!</h2>
        <p>
          We're working hard to bring you a revolutionary AI experience. Stay
          tuned for updates. Meanwhile, don’t hesitate to connect with us for
          any queries or feedback!
        </p>
        <div className="cta">
          <input
            type="email"
            placeholder="Enter your email to stay updated"
            className="email-input"
          />
          <button className="subscribe-button">Stay Updated</button>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
